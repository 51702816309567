import { render, staticRenderFns } from "./AdminContentOffers.vue?vue&type=template&id=4880cb70&scoped=true&"
import script from "./AdminContentOffers.vue?vue&type=script&lang=ts&"
export * from "./AdminContentOffers.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4880cb70",
  null
  
)

export default component.exports