import {BlogPostStatus} from "../models/api/blogs";
import BlogType from "../models/api/blogs/BlogType";
import PagedFilterBase from "./PagedFilterBase";

class BlogFilter extends PagedFilterBase {
    BusinessBranchId?: string;
    UserId?: string;
    Status = BlogPostStatus.None;
    RequireDisplayPublic = false;
    RequireDisplayMembers = false;
    BlogType: BlogType = BlogType.Blog;

    constructor(init?: Partial<BlogFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}

export default BlogFilter
